import { ProfileStyleEnum } from '@models/profile.model';
import { DocumentTypeEnum } from '@models/document.model';

export class ProfileFormDataModel {
  attributes: AttributeModel;
  locationCategories: LocationCategoryModel;
  mainCategories: MainCategoryModel[];
  specialtyCategories: SpecialtyCategoryModel[];
  styles: ProfileStyleModel[];
  configs: ProfileFromDataConfigsModel;

  static fromJson(json: any): ProfileFormDataModel {
    return {
      attributes: AttributeModel.fromJson(json.attributes),
      locationCategories: LocationCategoryModel.fromJson(
        json.location_categories
      ),
      mainCategories: json.main_categories
        ?.filter((item: any) => !EXCLUDED_MAIN_CATEGORIES.includes(item.value))
        .map((item: any) => MainCategoryModel.fromJson(item)),
      specialtyCategories: json.specialty_categories?.map((item: any) =>
        SpecialtyCategoryModel.fromJson(item)
      ),
      styles: json.styles?.map((item: any) => ProfileStyleModel.fromJson(item)),
      configs: ProfileFromDataConfigsModel.fromJson(json.configs)
    };
  }
}

export enum UnitTypeEnum {
  Royal = 'royal',
  Metric = 'metric'
}

export class ProfileFromDataConfigsModel {
  email: Pick<ValidPatterModel, 'validPattern'>;
  weekDays: string[];
  objectId: Pick<ValidPatterModel, 'validPattern'>;
  url: Pick<ValidPatterModel, 'validPattern'>;
  phone: Omit<ValidPatterModel, 'allowedAttributes' | 'allowedTags'>;
  callingCode: Omit<ValidPatterModel, 'allowedAttributes' | 'allowedTags'>;
  supportedLangs: string[];
  aaVersion: number;
  currencies: string[];
  ipv4Regex: string;
  shredderAccountNoServiceThreshold: number;
  shredderAccountInactivityThreshold: number;
  name: string;
  emojiRegex: string;
  showName: Omit<ValidPatterModel, 'allowedAttributes' | 'allowedTags'>;
  phoneNumber: Omit<ValidPatterModel, 'allowedAttributes' | 'allowedTags'>;
  tagline: Omit<ValidPatterModel, 'validPattern'>;
  visitingText: ValidPatterModel;
  age: Pick<ValidPatterModel, 'min' | 'max'>;
  ageOver: Pick<ValidPatterModel, 'min' | 'max'>;
  ageOverStep: number;
  phoneNumbersCount: number;
  emailsCount: number;
  websitesCount: number;
  transOperationGenders: string[];
  vipRequiredMainCats: string[];
  unitTypes: UnitTypeEnum[];
  defaultUnitType: UnitTypeEnum;
  bioText: Omit<ValidPatterModel<boolean>, 'validPattern'>;
  disclaimer: Omit<ValidPatterModel, 'validPattern'>;
  offersList: Omit<ValidPatterModel, 'validPattern'>;
  donations: Omit<ValidPatterModel, 'validPattern'>;
  magazineReference: Omit<ValidPatterModel, 'validPattern'>;
  movieReference: Omit<ValidPatterModel, 'validPattern'>;
  locationTags: Pick<ValidPatterModel, 'min' | 'max'>;
  maxEthnicities: number;
  royalHeight: Pick<ValidPatterModel, 'min' | 'max'>;
  heightFoot: Pick<ValidPatterModel, 'min' | 'max'>;
  heightInch: Pick<ValidPatterModel, 'min' | 'max'>;
  metricHeight: Pick<ValidPatterModel, 'min' | 'max'>;
  heightMetre: Pick<ValidPatterModel, 'min' | 'max'>;
  heightCentimetre: Pick<ValidPatterModel, 'min' | 'max'>;
  royalWeight: Pick<ValidPatterModel, 'min' | 'max'>;
  metricWeight: Pick<ValidPatterModel, 'min' | 'max'>;
  royalBustSize: Pick<ValidPatterModel, 'min' | 'max'>;
  metricBustSize: Pick<ValidPatterModel, 'min' | 'max'>;
  royalWaistSize: Pick<ValidPatterModel, 'min' | 'max'>;
  metricWaistSize: Pick<ValidPatterModel, 'min' | 'max'>;
  royalHipSize: Pick<ValidPatterModel, 'min' | 'max'>;
  metricHipSize: Pick<ValidPatterModel, 'min' | 'max'>;
  availableForTypes: string[];
  outCallRadius: Pick<ValidPatterModel, 'min' | 'max'>;
  outCallRadiusStep: number;
  availableForAddress: Pick<ValidPatterModel, 'min' | 'max'>;
  socialTypes: string[];
  socialHandle: Pick<ValidPatterModel, 'min' | 'max'>;
  socialValidPatterns: SocialValidPatternModel;
  socialUrls: SocialValidPatternModel;
  socialSlogans: SocialValidPatternModel;
  vipProfileMaxPhotos: number;
  profilePhotos: Pick<ValidPatterModel, 'min' | 'max'>;
  documentTypes: DocumentTypeEnum[];
  documentDegree: number[];
  documentFlip: string[];
  documentInstructions: Pick<ValidPatterModel, 'min' | 'max'>;
  documentThirdPartyVerified: string[];
  defaultStyle: string;
  reviserBotAccountId: number;
  needApprovalFields: string[];
  declineReasonTypes: DeclineReasonTypeModel;
  flagWordFields: string[];
  competitorFields: string[];
  emailDeclineReasons: string[];
  videoDeclineReasons: string[];
  photoDeclineReasons: string[];
  textDeclineReasons: string[];
  aopsFields: AopsFieldsModel;
  ageRestriction: AgeRestrictionType;
  sumSub: SumSubModel;
  iDenfy: IDenfyModel;
  shredderDelayDays: number;
  flagWordsCacheTime: number;

  static fromJson(json: any): ProfileFromDataConfigsModel {
    return {
      email: ValidPatterModel.fromJson(json.email),
      weekDays: json.week_days,
      objectId: ValidPatterModel.fromJson(json.object_id),
      url: ValidPatterModel.fromJson(json.url),
      phone: ValidPatterModel.fromJson(json.phone),
      callingCode: ValidPatterModel.fromJson(json.calling_code),
      supportedLangs: json.supportedLangs,
      aaVersion: json.aa_version,
      currencies: json.currencies,
      ipv4Regex: json.ipv4_regex,
      shredderAccountNoServiceThreshold:
        json.shredder_account_no_service_threshold,
      shredderAccountInactivityThreshold:
        json.shredder_account_inactivity_threshold,
      name: json.name,
      emojiRegex: json.emoji_regex,
      showName: ValidPatterModel.fromJson(json.showname),
      phoneNumber: ValidPatterModel.fromJson(json.phone_number),
      tagline: ValidPatterModel.fromJson(json.tagline),
      visitingText: ValidPatterModel.fromJson(json.visiting_text),
      age: ValidPatterModel.fromJson(json.age),
      ageOver: ValidPatterModel.fromJson(json.age_over),
      ageOverStep: json.age_over_step,
      phoneNumbersCount: json.phone_numbers_count,
      emailsCount: json.emails_count,
      websitesCount: json.websites_count,
      transOperationGenders: json.trans_operation_genders,
      vipRequiredMainCats: json.vip_required_main_cats,
      unitTypes: json.unit_types,
      defaultUnitType: json.default_unit_type,
      bioText: ValidPatterModel.fromJson<boolean>(json.bio_text),
      disclaimer: ValidPatterModel.fromJson(json.disclaimer),
      offersList: ValidPatterModel.fromJson(json.offers_list),
      donations: ValidPatterModel.fromJson(json.donations),
      magazineReference: ValidPatterModel.fromJson(json.magazine_reference),
      movieReference: ValidPatterModel.fromJson(json.movie_reference),
      locationTags: ValidPatterModel.fromJson(json.location_tags),
      maxEthnicities: json.max_ethnicities,
      royalHeight: ValidPatterModel.fromJson(json.royal_height),
      heightFoot: ValidPatterModel.fromJson(json.height_foot),
      heightInch: ValidPatterModel.fromJson(json.height_inch),
      metricHeight: ValidPatterModel.fromJson(json.metric_height),
      heightMetre: ValidPatterModel.fromJson(json.height_metre),
      heightCentimetre: ValidPatterModel.fromJson(json.height_centimetre),
      royalWeight: ValidPatterModel.fromJson(json.royal_weight),
      metricWeight: ValidPatterModel.fromJson(json.metric_weight),
      royalBustSize: ValidPatterModel.fromJson(json.royal_bust_size),
      metricBustSize: ValidPatterModel.fromJson(json.metric_bust_size),
      royalWaistSize: ValidPatterModel.fromJson(json.royal_waist_size),
      metricWaistSize: ValidPatterModel.fromJson(json.metric_waist_size),
      royalHipSize: ValidPatterModel.fromJson(json.royal_hip_size),
      metricHipSize: ValidPatterModel.fromJson(json.metric_hip_size),
      availableForTypes: json.available_for_types,
      outCallRadius: ValidPatterModel.fromJson(json.outcall_radius),
      outCallRadiusStep: json.outcall_radius_step,
      availableForAddress: ValidPatterModel.fromJson(
        json.available_for_address
      ),
      socialTypes: json.social_types,
      socialHandle: ValidPatterModel.fromJson(json.social_handle),
      socialValidPatterns: SocialValidPatternModel.fromJson(
        json.social_valid_patterns
      ),
      socialUrls: SocialValidPatternModel.fromJson(json.social_urls),
      socialSlogans: SocialValidPatternModel.fromJson(json.social_slogans),
      vipProfileMaxPhotos: json.vip_profile_max_photos,
      profilePhotos: ValidPatterModel.fromJson(json.profile_photos),
      documentTypes: json.document_types,
      documentDegree: json.document_degree,
      documentFlip: json.document_flip,
      documentInstructions: ValidPatterModel.fromJson(
        json.document_instructions
      ),
      documentThirdPartyVerified: json.document_third_party_verified,
      defaultStyle: json.default_style,
      reviserBotAccountId: json.revisor_bot_account_id,
      needApprovalFields: json.need_approvation_fields,
      declineReasonTypes: DeclineReasonTypeModel.fromJson(
        json.decline_reason_types
      ),
      flagWordFields: json.flagword_fields,
      competitorFields: json.competitor_fields,
      emailDeclineReasons: json.email_decline_reasons,
      videoDeclineReasons: json.video_decline_reasons,
      photoDeclineReasons: json.photo_decline_reasons,
      textDeclineReasons: json.text_decline_reasons,
      aopsFields: AopsFieldsModel.fromJson(json.aops_fields),
      ageRestriction: json.age_restriction,
      sumSub: SumSubModel.fromJson(json.sumsub),
      iDenfy: IDenfyModel.fromJson(json.iDenfy),
      shredderDelayDays: json.shredder_delay_days,
      flagWordsCacheTime: json.flagwords_cache_time
    };
  }
}

class IDenfyModel {
  apiKey: string;
  secretKey: string;
  baseUrl: string;
  redirectUrl: string;

  static fromJson(json: any): IDenfyModel {
    return {
      apiKey: json.api_key,
      secretKey: json.secret_key,
      baseUrl: json.base_url,
      redirectUrl: json.redirect_url
    };
  }
}

class SumSubModel {
  token: string;
  secretKey: string;
  baseUrl: string;
  privateKey: string;
  levelName: string;

  static fromJson(json: any): SumSubModel {
    return {
      token: json.token,
      secretKey: json.secret_key,
      baseUrl: json.base_url,
      privateKey: json.private_key,
      levelName: json.level_name
    };
  }
}

class AopsFieldsModel {
  photo: string;
  showName: string;
  tagline: string;
  visiting_text: string;
  bio_text: string;
  offers_list: string;
  donations: string;
  social_fields: string;
  youtube: string;
  facebook: string;
  twitter: string;
  googlePlus: string;
  snapchat: string;
  vine: string;
  tumblr: string;
  periscope: string;
  instagram: string;
  tiktok: string;
  email: string;

  static fromJson(json: any): AopsFieldsModel {
    return {
      photo: json.photo,
      showName: json.showname,
      tagline: json.tagline,
      visiting_text: json.visiting_text,
      bio_text: json.bio_text,
      offers_list: json.offers_list,
      donations: json.donations,
      social_fields: json.social_fields,
      youtube: json.youtube,
      facebook: json.facebook,
      twitter: json.twitter,
      googlePlus: json['google plus'],
      snapchat: json.snapchat,
      vine: json.vine,
      tumblr: json.tumblr,
      periscope: json.periscope,
      instagram: json.instagram,
      tiktok: json.tiktok,
      email: json.email
    };
  }
}

class DeclineReasonTypeModel {
  flaggedContent: string;
  video: string;
  expiredDocuments: string;
  urlOrWebsite: string;
  unalteredPhotoRequest: string;
  unalteredPhotoDenied: string;
  advertiserAgreement: string;
  authenticationRequest: string;
  authenticationRequestDenied: string;
  identificationDocumentRequest: string;
  specialAuthenticationPhotoRequest: string;
  livenessRequired: string;
  other: string;

  static fromJson(json: any): DeclineReasonTypeModel {
    return {
      flaggedContent: json.flagged_content,
      video: json.video,
      expiredDocuments: json.expired_documents,
      urlOrWebsite: json.url_or_website,
      unalteredPhotoRequest: json.unaltered_photo_request,
      unalteredPhotoDenied: json.unaltered_photo_denied,
      advertiserAgreement: json.advertiser_agreement,
      authenticationRequest: json.authentication_request,
      authenticationRequestDenied: json.authentication_request_denied,
      identificationDocumentRequest: json.identification_document_request,
      specialAuthenticationPhotoRequest:
        json.special_authentication_photo_request,
      livenessRequired: json.liveness_required,
      other: json.other
    };
  }
}

class SocialValidPatternModel {
  youtube: string;
  facebook: string;
  twitter: string;
  googlePlus: string;
  snapchat: string;
  vine: string;
  tumblr: string;
  periscope: string;
  instagram: string;
  tiktok: string;

  static fromJson(json: any): SocialValidPatternModel {
    return {
      youtube: json.youtube,
      facebook: json.facebook,
      twitter: json.twitter,
      googlePlus: json['google plus'],
      snapchat: json.snapchat,
      vine: json.vine,
      tumblr: json.tumblr,
      periscope: json.periscope,
      instagram: json.instagram,
      tiktok: json.tiktok
    };
  }
}

export class ValidPatterModel<T = any[]> {
  validPattern: string;
  min: number;
  max: number;
  allowedTags: any[];
  allowedAttributes: T;

  static fromJson<T = any[]>(json: any): ValidPatterModel<T> {
    const result: ValidPatterModel<T> = {
      validPattern: json.valid_pattern,
      min: json.min,
      max: json.max,
      allowedTags: json.allowed_tags,
      allowedAttributes: json.allowed_attributes
    };

    for (const key in result) {
      if (typeof result[key] === 'undefined') {
        delete result[key];
      }
    }

    return result;
  }
}

export class ProfileStyleModel {
  id: string;
  name: ProfileStyleEnum;
  class: IStyleClass;
  blockColor: string;
  ordering: number;
  isVisible: boolean;

  static fromJson(json: any): ProfileStyleModel {
    return {
      id: json._id,
      name: json.name,
      class: json.class,
      blockColor: json.block_color,
      ordering: json.ordering,
      isVisible: json.is_visible
    };
  }
}

interface IStyleClass {
  standard: string;
  bdsm: string;
  vip: string;
}

export class SpecialtyCategoryModel {
  id: string;
  value: ProfileSpecialtyCategoryEnum;
  requiredGenders: string[];
  requiredMainCategories: string[];
  incompatibilities: string[];
  erosId: number;
  erosType: number;
  price?: number;
  checked?: boolean;
  disabled?: boolean;

  static fromJson(json: any): SpecialtyCategoryModel {
    return {
      id: json._id,
      value: json.value,
      requiredGenders: json.required_genders,
      requiredMainCategories: json.required_main_categories,
      incompatibilities: json.incompatibilities,
      erosId: json.eros_id,
      erosType: json.eros_type
    };
  }
}

class MainCategoryModel {
  id: string;
  value: ProfileMainCategoryEnum;
  requiredGenders: string[];
  incompatibilities: string[];
  erosId: number;
  erosType: number;

  static fromJson(json: any): MainCategoryModel {
    return {
      id: json._id,
      value: json.value,
      requiredGenders: json.required_genders,
      incompatibilities: json.incompatibilities,
      erosId: json.eros_id,
      erosType: json.eros_type
    };
  }
}

class LocationCategoryModel {
  [key: string]: ILocationCategoryItem[];

  static fromJson(json: any): LocationCategoryModel {
    const result: LocationCategoryModel = {};

    if (!json) {
      return null;
    }

    for (const jsonKey in json) {
      if (json[jsonKey]) {
        result[jsonKey] = json[jsonKey].map((item) => ({
          id: item._id,
          value: item.value,
          requiredLocations: item.required_locations,
          incompatibilities: item.incompatibilities,
          erosId: item.eros_id,
          erosType: item.eros_type,
          isVisible: item.is_visible
        }));
      }
    }

    return result;
  }
}

interface ILocationCategoryItem {
  id: string;
  value: string;
  requiredLocations: number[];
  incompatibilities: any[];
  erosId: number;
  erosType: number;
  isVisible: boolean;
}

class AttributeModel {
  cupSize: AttributeOptionModel;
  eyeColor: AttributeOptionModel;
  alternative: AttributeOptionModel;
  gender: AttributeOptionModel;
  hairColor: AttributeOptionModel;
  availability: AvailabilityModel;
  affiliation: AttributeOptionModel;
  opStatus: AttributeOptionModel;
  ethnicity: AttributeOptionModel;

  static fromJson(json: any): AttributeModel {
    if (!json) {
      return null;
    }

    return {
      cupSize: AttributeOptionModel.fromJson(json.cup_size),
      eyeColor: AttributeOptionModel.fromJson(json.eye_color),
      alternative: AttributeOptionModel.fromJson(json.alternative),
      gender: AttributeOptionModel.fromJson(json.gender),
      hairColor: AttributeOptionModel.fromJson(json.hair_color),
      availability: AvailabilityModel.fromJson(json.availability),
      affiliation: AttributeOptionModel.fromJson(json.affiliation),
      opStatus: AttributeOptionModel.fromJson(json.op_status),
      ethnicity: AttributeOptionModel.fromJson(json.ethnicity)
    };
  }
}

class AvailabilityModel {
  id: string;
  type: string;
  values: string[];
  erosIds: number[];
  erosCategoryIds: IErosCategoryId[];
  categories: ICategories;

  static fromJson(json: any): AvailabilityModel {
    return {
      id: json._id,
      type: json.type,
      values: json.values,
      erosIds: json.eros_ids,
      erosCategoryIds: json.eros_category_ids.map((item) => ({
        mainCategory: json.mainCategory,
        categoryIds: json.category_ids,
        genders: json.genders
      })),
      categories: json.categories
    };
  }
}

interface IErosCategoryId {
  mainCategory?: string;
  categoryIds: number[];
  genders?: string[];
}

interface ICategories {
  [key: number]: string;
}

class AttributeOptionModel {
  id: string;
  type: string;
  values: string[];
  erosIds: number[];
  erosCategoryIds?: number[];
  categories?: ICategories;

  static fromJson(json: any): AttributeOptionModel {
    const result: AttributeOptionModel = {
      id: json._id,
      type: json.type,
      values: json.values,
      erosIds: json.eros_ids,
      erosCategoryIds: json.eros_category_ids,
      categories: json.categories
    };

    for (const key in result) {
      if (typeof result[key] === 'undefined') {
        delete result[key];
      }
    }

    return result;
  }
}

export enum ProfileMainCategoryEnum {
  Escort = 'escort',
  Bdsm = 'bdsm',
  Fantasy = 'fantasy',
  Dancer = 'dancer',
  Tantra = 'tantra',
  MaleEscort = 'male escort',
  Massage = 'massage',
  TransEscort = 'trans escort'
}

export enum ProfileSpecialtyCategoryEnum {
  Centerfold = 'centerfold',
  European = 'european',
  Bbw = 'bbw',
  Mature = 'mature',
  Xxx = 'xxx',
  Alternative = 'alternative',
  SuperBooty = 'super booty',
  CollegeGirls = 'college girls',
  Petite = 'petite',
  AllNatural = 'all natural',
  Dominant = 'dominant',
  Submissive = 'submissive',
  Switch = 'switch',
  Gfe = 'gfe'
}

enum aa {
  'all natural',
  'alternative',
  'bbw',
  'centerfold',
  'college girls',
  'mature',
  'petite',
  'super booty',
  'xxx'
}

export const EXCLUDED_MAIN_CATEGORIES = [
  ProfileMainCategoryEnum.Fantasy,
  ProfileMainCategoryEnum.Dancer,
  ProfileMainCategoryEnum.Tantra,
  ProfileMainCategoryEnum.Bdsm,
  ProfileMainCategoryEnum.MaleEscort
];

export const EXCLUDED_SPECIALTY_CATEGORIES = [
  ProfileSpecialtyCategoryEnum.Dominant,
  ProfileSpecialtyCategoryEnum.Submissive,
  ProfileSpecialtyCategoryEnum.Switch,
  ProfileSpecialtyCategoryEnum.Gfe
];

export type AgeRestrictionType = Partial<
  Record<ProfileSpecialtyCategoryEnum, Pick<ValidPatterModel, 'min' | 'max'>>
>;
